import {configureStore, getDefaultMiddleware} from '@reduxjs/toolkit'
import {ExperimentNames, isPersonalAgendaEnabled} from '@wix/wix-events-commons-statics'
import type {ControllerParams, CreateControllerFn} from '@wix/yoshi-flow-editor'
import {createEventHandler} from '@wix/tpa-settings'
import {createUouBiMiddlewareWithBiParams} from '../../commons/bi/bi'
import {getExportedActions} from './Widget/actions/exported-actions'
import * as eventsUou from './Widget/bi/uou-bi-events-map'
import {getBookmarks, getScheduleData} from './Widget/actions/schedule'
import {createApi} from './Widget/api/api'
import {rootReducer, State} from './Widget/reducers'
import {getEventDetailsUrl} from './Widget/services/location'
import {getDays, getLocations, getTags} from './Widget/services/schedule'
import {EventScheduleProps, SettingsEvents} from './Widget/interfaces'
import {schedulePageLoaded} from './Widget/actions/page-loaded'
import {decodeInstance} from './Widget/services/instance'
import {checkMembersAreaInstallation} from './Widget/actions/installed-apps'
import {loadCurrentUser, addLoginListener} from './Widget/actions/member'
import {isUserLoggedIn} from './Widget/selectors/member'
import {SettingsTab, SettingsUpdatedEvent} from './constants'

const createController: CreateControllerFn = async controllerParams => {
  const componentEventHandler = createEventHandler<SettingsEvents>(
    controllerParams.controllerConfig.config.publicData.COMPONENT || {},
  )
  const schedulePageSEOEnabled = controllerParams.flowAPI.experiments.enabled(ExperimentNames.SEOScheduleURL)

  return {
    async pageReady() {
      const api = createApi(controllerParams)
      const {wixCodeApi, platformAPIs, appParams, compId} = controllerParams.controllerConfig
      const decodedInstance = decodeInstance(appParams.instance)
      const biMiddleware = createUouBiMiddlewareWithBiParams(
        {
          wixCodeApi,
          platformAPIs,
          appParams,
          compId,
          user: {
            aid: decodedInstance.aid,
            uid: decodedInstance.uid,
          },
        },
        eventsUou,
      )

      const store = configureStore({
        reducer: rootReducer,
        middleware: [biMiddleware, ...getDefaultMiddleware({thunk: {extraArgument: {api, controllerParams}}})],
      })

      if (wixCodeApi.location.onChange) {
        wixCodeApi.location.onChange(() => {
          store.dispatch(getScheduleData() as any)
        })
      }

      store.dispatch(addLoginListener() as any)
      const data = await store.dispatch(getScheduleData() as any)
      const {formattedEventDateAndLocation} = data.payload

      const {event} = store.getState()

      const personalAgendaEnabled = isPersonalAgendaEnabled(event)

      if (personalAgendaEnabled) {
        await Promise.all([
          store.dispatch(checkMembersAreaInstallation() as any),
          store.dispatch(loadCurrentUser() as any),
        ])
        if (isUserLoggedIn(store.getState().member)) {
          await store.dispatch(getBookmarks() as any)
        }
      }

      const actions = getExportedActions(store)
      const eventDetailsUrl = await getEventDetailsUrl(controllerParams, event)
      const props: EventScheduleProps = {
        ...getProps(store.getState(), controllerParams, formattedEventDateAndLocation),
        ...actions,
        eventDetailsUrl,
      }

      controllerParams.controllerConfig.setProps(props)

      const state = store.getState()
      if (wixCodeApi.window.viewMode === 'Site') {
        const totalScheduleItems = state.schedule.total
        store.dispatch(schedulePageLoaded({totalScheduleItems}))
      }

      if (state.error) {
        controllerParams.flowAPI.reportError(state.error.message)
      }

      store.subscribe(async () => {
        const newState = store.getState()
        const error = newState.error
        const newProps = await getProps(newState, controllerParams, formattedEventDateAndLocation)

        if (error) {
          controllerParams.flowAPI.reportError(error.message)
        }
        controllerParams.flowAPI.controllerConfig.setProps(newProps)
      })

      componentEventHandler.on(SettingsTab.ButtonDesign, (forceShowPersonalAgendaUI: SettingsUpdatedEvent) =>
        controllerParams.flowAPI.controllerConfig.setProps({forceShowPersonalAgendaUI}),
      )

      if (schedulePageSEOEnabled) {
        wixCodeApi.seo.renderSEOTags({
          itemType: 'SCHEDULE_PAGE',
          itemData: {event: store.getState().event},
        })
      }
    },
    updateConfig: (_, newConfig) => {
      componentEventHandler.notify(newConfig.publicData.COMPONENT || {})
    },
  }
}

const getProps = (
  state: State,
  controllerParams: ControllerParams,
  formattedEventDateAndLocation,
): EventScheduleProps => {
  const {member, installedApps, filter, modals, event, schedule} = state
  const {t} = controllerParams.flowAPI.translations
  const {location: selectedLocation, tags: selectedTags} = filter
  const locations = getLocations(schedule, t)
  const bookmarksCount = schedule.bookmarks.length
  const personalAgendaEnabled = isPersonalAgendaEnabled(event)

  return {
    formattedEventDateAndLocation,
    eventTitle: event?.title,
    locations,
    tags: getTags(schedule, selectedTags),
    days: getDays({schedule}),
    bookmarkedDays: getDays({schedule, bookmarkedOnly: true}),
    selectedLocation,
    modals,
    filter,
    scheduleTotal: schedule?.total ?? 0,
    installedApps,
    member,
    bookmarksCount,
    personalAgendaEnabled,
    forceShowPersonalAgendaUI: SettingsUpdatedEvent.ForceHide,
    // @ts-expect-error
    fitToContentHeight: true,
  }
}

export default createController
